import React from 'react';
import {ProgressRing} from '../components/ProgressRing';
import './Profile.scss';
import {Card} from '../components/Card';
import boonimg from '../assets/boon-software.png';
import rabobankimg from '../assets/rabobank.png';
import vbimg from '../assets/volksbank.png';
import naimg from '../assets/na.svg';
import dictuimg from '../assets/dictu.png';
import leoimg from '../assets/leo.png';
import philipsimg from '../assets/philips.png';

export class Profile extends React.Component {
  render() {
    const projectsList = [
      {
        name: 'Boon Software',
        period: '12/2019 - 03/2020',
        img: boonimg
      },
      {
        name: 'De Volksbank',
        period: '05/2018 - 11/2019',
        img: vbimg
      },
      {
        name: 'Rabobank',
        period: '08/2015 - 04/2018',
        img: rabobankimg
      },
      {
        name: 'Nationaal Archief',
        period: '10/2013 - 07/2015',
        img: naimg
      },
      {
        name: 'Dictu',
        period: '11/2011 - 09/2013',
        img: dictuimg
      },
      {
        name: 'Leo Kannerhuis',
        period: '05/2011 - 08/2011',
        img: leoimg
      },
      {
        name: 'Philips CMST',
        period: '01/2011 - 04/2011',
        img: philipsimg
      },
    ].map((project, i) => {
      return <li key={i}>
        <Card title={project.name} period={project.period}>
          <img src={project.img}/>
        </Card>
      </li>
    });

    return (
      <div className={'thc-profile'}>
        <section>
          <h2>MVC</h2>
          <div className="thc-profile__mvc">
            <ProgressRing name="Angular" percentage={90}/>
            <ProgressRing name="VueJS" percentage={60}/>
            <ProgressRing name="ReactJS" percentage={40}/>
          </div>
        </section>
        <section>
          <h2>Projects</h2>
          <div className={'thc-profile__projects'}>
            <ul className={'thc-profile__projects-list'}>
              {projectsList}
            </ul>
          </div>
        </section>
      </div>
    );
  }
}