import React from 'react';
import './Home.scss';

export class Home extends React.Component {
  render() {
    const skillsList = [
      'Angular & Typescript',
      'ReactJS',
      'VueJS',
      'Javascript & ES6',
      'Jenkins & Groovy scripting',
      'HTML5 & (S)CSS',
      'NodeJS & ExpressJS',
      'Cypress & CucumberJS',
      'Jasmine & Karma',
      'JIRA & GIT'
    ].map((skill, i) => <li key={i}>{skill}</li>);

    return (
      <div className="thc-home">
        <p>
          Hi, I am <span className={'thc-highlight'}>Tjin Hoo Cheng</span>, a <strong>front-end developer</strong> who
          also likes working with <strong>test automation</strong> and setting up <strong>CI/CD</strong>.
        </p>
        <p>
          <strong>Fast paced</strong> development with an eye for details.

          Produce high quality code covered with <strong>unit</strong>, <strong>component</strong> and <strong>integration</strong> testing.

          Experience with setting up <strong>software architecture</strong>.

          Closely working with the business to find the <strong>best solution</strong>.
        </p>
        <p className="thc-home__skills">
          I have worked with
        </p>
        <div className={'thc-home__skills-wrapper'}>
          <ul className={'thc-home__skills-list'}>
            {skillsList}
          </ul>
        </div>
      </div>
    );
  }
}