import React from 'react';
import {Nav} from './components/Navigation';
import './App.scss';
import {BrowserRouter as Router} from 'react-router-dom';
import Container from './components/Container';


function App() {
  return (
    <div className="thc-app">
      <Router>
        <Nav/>
        <div className="thc-app__wrapper">
          <div className={"thc-app__content"}>
            <Container></Container>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
