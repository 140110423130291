import React from 'react';
import './Card.scss';

export class Card extends React.Component {
  render() {
    const children = this.props.children;

    return (
      <div className="thc-card">
        <div className={'thc-card__image'}>
          {children}
        </div>
      </div>
    );
  }
}
