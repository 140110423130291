import React from 'react';
import './ProgressRing.scss';

export class ProgressRing extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="thc-progress-ring">
        <div className="thc-progress-ring__wrapper">
          <svg className="thc-progress-ring__svg" viewBox="0 0 140 140" preserveAspectRatio="xMidYMid meet">
            {/*<circle cx="70" cy="70" r="67" fill="white" className="thc-hidden"/>*/}
            <circle cx="70" cy="70" r="54" className="thc-progress-ring__circle"/>
            <circle cx="70" cy="70" r="54"
                    className={"thc-progress-ring__fill -p" + this.props.percentage}/>
          </svg>
          <div className={"thc-progress-ring__text -p"+this.props.percentage}>

          </div>
        </div>
        <h2>{this.props.name}</h2>
      </div>
    );
  }
}
