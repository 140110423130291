import {Profile} from '../pages/Profile';
import {Home} from '../pages/Home';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import React from 'react';
import {withRouter, Route, Switch} from 'react-router-dom';



function Container({ location }) {
  return (
      <TransitionGroup className="transition-group">
        <CSSTransition
          key={location.key}
          timeout={{ enter: 1000, exit: 1000 }}
          classNames="fade"
        >
          <section className="route-section">
            <Switch location={location}>
              <Route exact path="/" component={Home}/>
              <Route path="/profile" component={Profile}/>
            </Switch>
          </section>
        </CSSTransition>
      </TransitionGroup>
  );
}

export default withRouter(Container);