import React from 'react';
import {Link} from 'react-router-dom';
import './Navigation.scss';

export class Nav extends React.Component {
  render() {
    return (
      <nav className="thc-navigation">
        <ul className="thc-navigation__list">
          <li>
            <Link to="/">About me</Link>
          </li>
          <li>
            <Link to="/profile">Profile</Link>
          </li>
        </ul>
      </nav>
    )
  }
}